import React from 'react';
import SectionIntro from '../SectionIntro';
import IconCard from './IconCard';

const AssetCards = ({ iconCards, title, subtitle, theme }) => {

  return (
    <>
    <section className={`asset-cards asset-cards--${theme}`}>
      <SectionIntro title={title} subtitle={subtitle} />
      <div className="asset-cards__container">
        {iconCards.map((i) => (
          <IconCard variant="bordered" {...i} key={i.title} />
        ))}
      </div>
    </section>
    </>
  );
};


AssetCards.defaultProps = {
  iconCards: []
};

export default AssetCards;