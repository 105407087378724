import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import DeviceFrame from '../DeviceFrame';
import RichText from '../RichText';

import isMobile from '../../utils/isMobile';

import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { inViewTriggerThresholds } from '../../animationGlobals';

const DeviceHero = ({ title, body, deviceType, fallbackImage, asset }) => {
  // const isMobileLayout = isMobile();

  const sectionRef = useRef();
  const deviceRef = useRef();
  const contentRef = useRef();

  useEffect(() => {
    const sectionEl = sectionRef.current;
    const deviceEl = deviceRef.current;
    const contentEl = contentRef.current;

    const toCommon = {
      duration: 1,
      ease: 'power4.out',
    };

    const delayCommon = `<${toCommon.duration * 0.5}`;

    const InFromBottom = [
      {
        autoAlpha: 0,
        y: '25%',
      },
      {
        autoAlpha: 1,
        y: '0%',
        ...toCommon,
      },
      delayCommon,
    ];

    const animation = gsap
      .timeline({})
      .fromTo(deviceEl, ...InFromBottom)
      .fromTo(contentEl, ...InFromBottom);

    const trigger = ScrollTrigger.create({
      trigger: sectionEl,
      ...inViewTriggerThresholds,
      animation: animation,
    });

    return () => {
      animation.progress(1);
      animation.kill();
      trigger.kill();
    };
  }, []);

  return (
    <div ref={sectionRef} className={`device-hero device-hero--${deviceType}`}>
      <div className={'device-hero__inner'}>
        <div ref={deviceRef} className="device-hero__device">
          <div className="device-hero__device-inner">
            <DeviceFrame
              deviceType={deviceType}
              fallbackImage={fallbackImage}
              asset={asset}
            />
          </div>
        </div>
        <div ref={contentRef} className="device-hero__content">
          <h1 className="device-hero__title">{title}</h1>
          <div className="device-hero__body">
            <RichText source={body?.json} />
          </div>
        </div>
      </div>
    </div>
  );
};

DeviceHero.propTypes = {
  title: PropTypes.string,
  body: PropTypes.object,
  mediaUrl: PropTypes.string,
  deviceType: PropTypes.oneOf(['laptop', 'tablet', 'mobile']),
};

DeviceHero.defaultProps = {
  title: 'Hero title',
  body: null,
  mediaUrl: '',
  deviceType: 'laptop',
  fluid: null,
};

export default DeviceHero;
