import React, { useState, useRef, useEffect } from 'react';
import Image from 'gatsby-image';
import VideoPlaceholder from '../VideoPlaceholder';
import { useIntersection } from 'react-use';

const LazySVG = ({ url, className }) => {
  const objectRef = useRef();
  const [data, setData] = useState('');

  const intersection = useIntersection(objectRef, {
    threshold: 0.0,
  });

  useEffect(() => {
    const isIntersecting = intersection && intersection.isIntersecting;
    if (isIntersecting && data !== url) {
      setData(url);
    }
  }, [intersection?.isIntersecting]);

  return (
    <object
      ref={objectRef}
      type="image/svg+xml"
      data={data}
      className={className}
    />
  );
};
const MediaItem = ({
  videoCoverImage,
  asset,
  className,
  imageWrapperStyle = {},
  imageStyle = {},
}) => {
  const isVideo = asset?.file.contentType.includes('video');
  const isSvg = asset?.file.contentType.includes('svg');

  if (isVideo) {
    return (
      <div className={className}>
        <VideoPlaceholder
          src={asset.file.url}
          posterFluid={videoCoverImage.fluid}
        />
      </div>
    );
  } else if (isSvg) {
    return <LazySVG url={asset.file.url} className={className} />;
  } else if (asset?.fluid) {
    return (
      <div className={className}>
        <Image
          backgroundColor={true}
          fluid={asset.fluid}
          alt={asset?.description}
          style={imageWrapperStyle}
          imgStyle={imageStyle}
        />
      </div>
    );
  } else if (asset?.fixed) {
    return (
      <div className={className}>
        <Image
          backgroundColor={true}
          fixed={asset.fixed}
          alt={asset?.description}
          style={imageWrapperStyle}
          imgStyle={imageStyle}
        />
      </div>
    );
  }
  return null;
};

export default MediaItem;
