import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout';
import DeviceHero from '../../components/DeviceHero';
import { deviceDataFromDeviceAsset } from '../../utils/contentfulHelpers';
import AssetCards from '../../components/AssetCards';
import DigitalTools from '../../components/DigitalTools';
import DemoBanner from '../../components/DemoBanner';
import Wayfinding from '../../components/Wayfinding';
import ResourcesSection from '../../components/ResourcesSection';
import SiteMeta from '../../components/SiteMeta';

const AssetsPage = ({ data, location }) => {
  const {
    contentfulAssetLibraryPage: {
      metaTitle,
      metaDescription,
      heroModule,
      newAssetsSection,
      trustedProvidersSection,
      functionSpecificAssetsSection,
      generalPurposeAssetSection,
      automationUpskillingAssetsSection
    },
    contentfulEcosystemPage: {
      digitalToolsTitle,
      digitalToolsSubheader: { digitalToolsSubheader },
      digitalTools
    },
    contentfulSharePage: {
      demoCallout,
      wayfindingCardTitle,
      wayfindingCardSubheader: { wayfindingCardSubheader },
      wayfindingCards,
      featuredResources,
      featuredResourcesTitle,
      featuredResourcesSubheader: { featuredResourcesSubheader },
      featuredResourcesCtaText,
      featuredResourcesCtaLink,
    }
  } = data;
  const heroDeviceData = deviceDataFromDeviceAsset(heroModule.deviceAsset);

  const wayfindingData = {
    title: wayfindingCardTitle,
    subtitle: wayfindingCardSubheader,
    items: wayfindingCards.map(
      ({ wayfindingCardTitle, wayfindingCardBody, matchUrl }) => {
        return {
          title: wayfindingCardTitle,
          body: wayfindingCardBody.wayfindingCardBody,
          matchUrl,
        };
      },
    ),
    currentPath: location.pathname,
  };
  
  const resourcesData = {
    title: featuredResourcesTitle,
    subtitle: featuredResourcesSubheader,
    ctaText: featuredResourcesCtaText,
    ctaUrl: featuredResourcesCtaLink,
    featuredResources: featuredResources
  };

  return (
    <Layout>
      <SiteMeta title={metaTitle} description={metaDescription} />
      <DeviceHero
        title={heroModule.title}
        body={heroModule.body}
        {...heroDeviceData}
      />
      <AssetCards
        theme={"white"}
        title={newAssetsSection.sectionTitle}
        subtitle={newAssetsSection.sectionSubheading}
        iconCards={newAssetsSection.iconCards}
      />
      <DigitalTools
        theme={"light"}
        title={digitalToolsTitle}
        subtitle={digitalToolsSubheader}
        items={digitalTools}
      />
      <AssetCards
        theme={"white"}
        title={functionSpecificAssetsSection.sectionTitle}
        subtitle={functionSpecificAssetsSection.sectionSubheading}
        iconCards={functionSpecificAssetsSection.iconCards}
      />
      <AssetCards
        theme={"light"}
        title={generalPurposeAssetSection.sectionTitle}
        subtitle={generalPurposeAssetSection.sectionSubheading}
        iconCards={generalPurposeAssetSection.iconCards}
      />
      <AssetCards
        theme={"white"}
        title={automationUpskillingAssetsSection.sectionTitle}
        subtitle={automationUpskillingAssetsSection.sectionSubheading}
        iconCards={automationUpskillingAssetsSection.iconCards}
      />
      <Wayfinding {...wayfindingData} />
      <DemoBanner {...demoCallout} theme="dark" />
      <ResourcesSection {...resourcesData} />
    </Layout>
  );
};

export default AssetsPage;

export const query = graphql`
  query {
    contentfulAssetLibraryPage {
      metaTitle
      metaDescription {
        metaDescription
      }
      heroModule {
        ...DeviceHero
      }
      newAssetsSection {
        ...IconCardsSection
      }
      trustedProvidersSection {
        sectionTitle
        sectionSubtitle: sectionSubtitle {
          content {
            content {
              value
            }
          }
        }
        partnerLogos {
          description
          file {
            url
          }
        }
      }
      functionSpecificAssetsSection {
        ...IconCardsSection
      }
      generalPurposeAssetSection {
        ...IconCardsSection
      }
      automationUpskillingAssetsSection {
        ...IconCardsSection
      }
    }
    contentfulEcosystemPage {
      digitalToolsTitle
      digitalToolsSubheader {
        digitalToolsSubheader
      }
      digitalTools {
        id
        url
        digitalToolPartnerIcon {
          file {
            url
            contentType
          }
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
    contentfulSharePage {
      demoCallout {
        ...Navigator
      }
      wayfindingCardTitle
      wayfindingCardSubheader {
        wayfindingCardSubheader
      }
      wayfindingCards {
        ...WayfindingCard
      }
      featuredResources {
       ...ResourceCard
      }
      featuredResourcesTitle
      featuredResourcesSubheader {
       featuredResourcesSubheader
      }
      featuredResourcesCtaText
      featuredResourcesCtaLink
    }
  }
`;
