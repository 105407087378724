import React from 'react';
import styled, { css } from 'styled-components';
import { Heading, Paragraph, Image } from 'grommet';

const IconCard = (props) => {
  const { smallIcon, largeIcon, iconTitle, title, body, variant } = props;
  return (
    <div className="icon-cards">
      <div className="icon-cards__header">
        <Image
          src={smallIcon.file.url}
          width={'16px'}
          alt={smallIcon.description}
        />
        {iconTitle && <div className="icon-cards__header__eyebrow">{iconTitle}</div>}
      </div>
      <div>
        <Heading level="4">{title}</Heading>
        <Paragraph style={{paddingTop:"10px", fontSize:"16px"}}>{body}</Paragraph>
      </div>
    </div>
  );
};

IconCard.defaultProps = {
  variant: 'bordered',
  iconTitle: null,
};

export default IconCard;
