import React, { useEffect, useRef } from 'react';
import Scrollbar from 'smooth-scrollbar';
import { isMobile } from '../../utils';
import SectionIntro from '../SectionIntro';
import Link from '../Link';
import MediaItem from '../MediaItem';

const DigitalTools = ({ items, title, subtitle, theme }) => {
  const scrollerRef = useRef();
  const mobile = isMobile();

  useEffect(() => {
    if (!mobile) {
      return;
    }
    const scrollerEl = scrollerRef.current;
    const scrollbar = Scrollbar.init(scrollerEl);
    return () => {
      scrollbar.destroy();
    };
  }, [mobile]);

  return (
    <section className={`digital-tools digital-tools--${theme}`}>
      <SectionIntro title={title} subtitle={subtitle} />
      <div className="digital-tools__scroller" ref={scrollerRef}>
        <div className="digital-tools__container">
          {items.map(({ url, digitalToolPartnerIcon }, i) => (
            <Link
              href={url}
              className="digital-tools__item"
              key={`digital-tools__item${i}`}
            >
              <MediaItem
                asset={digitalToolPartnerIcon}
                className="digital-tools__image"
                imageStyle={{ objectFit: 'scale-down' }}
              />
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
};


DigitalTools.defaultProps = {
  items: [],
};

export default DigitalTools;
