import React, { useState, useRef, useEffect } from 'react';
import Scrollbar from 'smooth-scrollbar';
import trim from 'lodash/trim';
import { navigate } from 'gatsby';
import SectionIntro from '../SectionIntro/SectionIntro';

const WayfindingSliderItem = ({
  title,
  body,
  isActive,
  highlight = false,
  matchUrl,
  ...rest
}) => {
  return (
    <div
      className={`wayfinding-slider__item wayfinding-slider__item--${
        isActive ? 'active' : 'not-active'
      }`}
      {...rest}
    >
      <div
        className={`wayfinding-slider__item-inner ${
          highlight ? 'highlight' : ''
        }`}
      >
        <div className="wayfinding-slider__item-indicator">
          <svg
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 40 20"
          >
            <path d="M19.524 20L0 0h39.048L19.524 20z" fill="#2D2D2D" />
          </svg>
        </div>

        <h2 className="wayfinding-slider__item-title">{title}</h2>
        <p className="wayfinding-slider__item-body">{body}</p>
      </div>
    </div>
  );
};
const scrollToItem = (targetItem, scrollContainerEl) => {
  const scrollTarget = targetItem
    ? targetItem.offsetLeft +
      targetItem.offsetWidth / 2 -
      scrollContainerEl.offsetWidth / 2 // centering the element
    : 0;
  Scrollbar.get(scrollContainerEl).scrollTo(scrollTarget, 0, 500);
};
const WayfindingSlider = ({ items, currentPath }) => {
  const activeItem = items.findIndex(
    (item) => trim(currentPath, '/') === trim(item.matchUrl, '/'),
  );
  const [targetItemIndex, setTargetItemIndex] = useState(activeItem);
  const scrollContainerRef = useRef();
  const itemsContainerRef = useRef();

  const handleItemClick = (index) => {
    const elements = itemsContainerRef.current.children;
    const targetItem = elements[index];

    navigate(items[index].matchUrl);

    if (targetItem) {
      setTargetItemIndex(index);
      scrollToItem(targetItem, scrollContainerRef.current);
    }
  };

  useEffect(() => {
    const scrollContainerEl = scrollContainerRef.current;
    const scrollbar = Scrollbar.init(scrollContainerEl, {
      continuousScrolling: true,
    });

    const elements = itemsContainerRef.current.children;
    const targetItem = elements[targetItemIndex];
    scrollToItem(targetItem, scrollContainerEl);

    return () => {
      scrollbar.destroy();
    };
  }, []);

  return (
    <div className="wayfinding-slider">
      <div
        className="wayfinding-slider__scroll-container"
        ref={scrollContainerRef}
      >
        <div className="wayfinding-slider__items" ref={itemsContainerRef}>
          {items?.map((item, index) => {
            const isActive =
              trim(currentPath, '/') === trim(item.matchUrl, '/');
            return (
              <WayfindingSliderItem
                {...item}
                onClick={() => handleItemClick(index)}
                isActive={isActive}
                key={index}
                highlight={!isActive && index === targetItemIndex}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

const Wayfinding = ({ currentPath, title, subtitle, items }) => {
  return (
    <div className={'wayfinding'}>
      <div className="wayfinding__inner">
        <div className={`wayfinding__intro`}>
          <SectionIntro title={title} subtitle={subtitle} />
        </div>

        <WayfindingSlider currentPath={currentPath} items={items} />
      </div>
    </div>
  );
};

Wayfinding.defaultProps = {
  title: 'The end-to-end upskilling experience',
  subtitle:
    'Keeping your workforce ahead of the curve is an ongoing task that ProEdge does for you. From planning to skill mastery, ProEdge covers it all with four key features.',
  items: [
    {
      title: 'Plan',
      body:
        'Automatically pinpoint skill gaps and see where your organization stands.',
      matchUrl: '/features/plan',
    },
    {
      title: 'Learn',
      body:
        'Access personalized learning pathways to effectively close skill gaps.',
      matchUrl: '/features/learn',
    },
    {
      title: 'Create',
      body: 'Give your people a secure, hands-on learning environment.',
      matchUrl: '/features/create',
    },
    {
      title: 'Share',
      body: 'Experience innovation shared throughout your organization.',
      matchUrl: '/features/share',
    },
  ],
};

export default Wayfinding;
